// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import MainView from '@/components/MainView.vue';
import CvCompView from '@/components/CvCompView.vue';
import ArtStateView from '@/components/ArtStateView.vue';
import ImageCompView from '@/components/ImageCompView.vue';
import AproComp from '@/components/project/AproComp.vue';
import NetworkGalleryView from '@/components/work/NetworkGalleryView.vue';
import ContentDetailView from '@/components/work/ContentDetailView.vue'; // 상세 페이지 컴포넌트 추가

const routes = [
  { path: '/', name: 'MainView', component: MainView },
  { path: '/about', name: 'CvCompView', component: CvCompView },
  { path: '/text', name: 'ArtStateView', component: ArtStateView },
  { path: '/work/:workId', name: 'ImageCompView', component: ImageCompView },
  { path: '/project', name: 'AproComp', component: AproComp },
  { path: '/research/:workId', name: 'NetworkGalleryView', component: NetworkGalleryView },
  {
    path: '/research/:workId/content/:contentId', // 변경된 경로
    name: 'ContentDetailView',
    component: ContentDetailView,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;