<!-- 20240918_메인 페이지 최근 이미지컴포넌트 셋 -->

<template>
  <div class="container">
    <div 
      v-for="(episode, index) in shuffledEpisodes" 
      :key="episode.id || index"
      class="img-wrap"
      :style="getDelayStyle(index)"
    >
      <img 
        v-if="episode.type === 'image'" 
        :src="placeholderImage"
        :data-src="episode.thumbnail" 
        :alt="episode.description" 
        class="responsive-media fade-in" 
        ref="images"
        @error="setDefaultImage"
      >
      <iframe 
        v-else-if="episode.type === 'video'" 
        :src="convertYoutubeLink(episode.videoLink)" 
        class="responsive-media video fade-in" 
        frameborder="0" 
        allowfullscreen
      ></iframe>
      <span v-if="episode.description" class="description">{{ episode.description }}</span>
    </div>
  </div>
</template>

<script>
import placeholderImage from '@/assets/placeholder-image.png';

export default {
  name: 'RecentCompView',
  props: {
    episodes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      placeholderImage: placeholderImage,
      shuffledEpisodes: []
    };
  },
  mounted() {
    this.shuffleEpisodes();
    this.$nextTick(() => {
      this.initIntersectionObserver();
    });
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.placeholderImage;
      event.target.classList.add('error-image');
    },
    convertYoutubeLink(link) {
      return link.replace('watch?v=', 'embed/');
    },
    getDelayStyle(index) {
      return { animationDelay: `${index * 100}ms` };
    },
    shuffleEpisodes() {
      this.shuffledEpisodes = [...this.episodes].sort(() => Math.random() - 0.5);
    },
    initIntersectionObserver() {
      const observer = new IntersectionObserver(this.handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      });

      this.$refs.images.forEach(img => {
        if (img) observer.observe(img);
      });
    },
    handleIntersection(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const img = entry.target;
          const highResSrc = img.getAttribute('data-src');
          if (highResSrc) {
            const tempImg = new Image();
            tempImg.src = highResSrc;
            tempImg.onload = () => {
              img.src = highResSrc;
              img.classList.add('loaded');
              observer.unobserve(img);
            };
          }
        }
      });
    }
  }
};
</script>

<style scoped>
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
  grid-auto-rows: minmax(200px, auto);
  gap: 5px;
}

.img-wrap {
  position: relative;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.responsive-media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: opacity 0.5s ease;
}

.responsive-media.loaded {
  opacity: 1;
}

.description {
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px;
  border-radius: 2px;
  font-size: 11px;
  max-width: 80%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 768px) {
  .container { grid-template-columns: repeat(2, 1fr); }
  .description { max-width: calc(100% - 40px); }
}

@media screen and (max-width: 480px) {
  .container { grid-template-columns: 1fr; }
}
</style>
